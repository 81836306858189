var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v4-project-detail-container"},[(!_vm.$bowserMobile)?[(_vm.project)?_c('div',{staticClass:"project-info-container"},[_c('div',{staticClass:"project-name"},[_c('p',[_vm._v(_vm._s(_vm.project.name.en))]),_c('p',[_vm._v(_vm._s(_vm.project.name.cn))])]),_c('div',{staticClass:"other-info"},[(_vm.project.textarea.length > 0)?_c('div',{staticClass:"pc__direct-bts",class:{
            active: _vm.isShowDesc
          },on:{"click":_vm.onClickDesc}},[(_vm.isShowDesc)?[_c('p',[_vm._v("Gallery")]),_c('p',[_vm._v("案例图片")])]:[_c('p',[_vm._v("Description")]),_c('p',[_vm._v("案例详情")])]],2):_vm._e(),(_vm.projectPrev)?_c('router-link',{staticClass:"pc__direct-bts prev ajustWidth",attrs:{"tag":"div","to":{
            path: ("/project/" + (_vm.projectPrev.id) + "?parentid=" + (_vm.project.parentid))
          }},nativeOn:{"mouseenter":function($event){return _vm.onLineHoverEnter($event)},"mouseleave":function($event){return _vm.onLineHoverLeave($event)}}},[_c('p',[_vm._v("Previous")]),_c('p',[_vm._v("上一个")]),_c('div',{staticClass:"line"})]):_vm._e(),(_vm.projectNext)?_c('router-link',{staticClass:"pc__direct-bts next ajustWidth",attrs:{"tag":"div","to":{
            path: ("/project/" + (_vm.projectNext.id) + "?parentid=" + (_vm.project.parentid))
          }},nativeOn:{"mouseenter":function($event){return _vm.onLineHoverEnter($event)},"mouseleave":function($event){return _vm.onLineHoverLeave($event)}}},[_c('p',[_vm._v("Next")]),_c('p',[_vm._v("下一个")]),_c('div',{staticClass:"line"})]):_vm._e()],1)]):_vm._e(),(_vm.project)?_c('div',{staticClass:"fixed-back-container",on:{"click":_vm.onBackList}},[_vm._m(0),_c('div',{staticClass:"text-container"},[_vm._v("back")])]):_vm._e(),(!_vm.isShowDesc && _vm.pics)?_c('div',{staticClass:"v4-scroll-list-container"},[_c('div',{staticClass:"scroll-hint-container",class:{
          loading: !_vm.loaded
        }},[_c('p',[_vm._v(_vm._s(_vm.loaded ? "scroll" : "loading"))]),_c('div',{staticClass:"line"})]),_c('div',{staticClass:"slide-element-list",attrs:{"id":"slide-element-list"}},[_c('div',{staticClass:"slide-element"},[_c('div',{staticClass:"slide-element--inner"},[_c('div',{staticClass:"case-elements"},_vm._l((_vm.pics),function(item,index){return _c('div',{key:index,staticClass:"case-element"},[_c('div',{staticClass:"case-element--inner"},[_c('div',{staticClass:"image--outer",style:({
                      opacity: _vm.loaded ? 1 : 0
                    })},[_c('div',{staticClass:"image"},[_c('img',{staticClass:"lazyload",attrs:{"src":"","data-src":item,"alt":""}})]),_c('div',{staticClass:"imageBg"})])])])}),0)])])])]):_vm._e(),(_vm.project && _vm.project.textarea)?_c('div',{staticClass:"v4-fixed-detail",class:{
        active: _vm.isShowDesc
      }},[_c('div',{staticClass:"content"},_vm._l((_vm.project.textarea),function(p,index){return _c('div',{key:index,staticClass:"content__item",domProps:{"innerHTML":_vm._s(p)}})}),0)]):_vm._e()]:[(_vm.project)?_c('div',{staticClass:"project-detail-wrapper"},[_c('div',{staticClass:"mobile-detail-title"},[_c('p',{staticClass:"en scrollObj"},[_vm._v(_vm._s(_vm.project.name.en))]),_c('p',{staticClass:"cn scrollObj"},[_vm._v(_vm._s(_vm.project.name.cn))])]),_c('div',{staticClass:"mobile-detail-content-container"},_vm._l((_vm.project.content),function(item,index){return _c('div',{key:index,staticClass:"content__item scrollObj",class:item.type,domProps:{"innerHTML":_vm._s(_vm.renderHtml(item))}})}),0),(_vm.project.items.length > 0)?_c('div',{staticClass:"mobile-detail-items-container"},[_c('div',{staticClass:"inner scrollObj"},_vm._l((_vm.project.items),function(info){return _c('div',{key:info.id,staticClass:"item__box scrollObj"},[_c('p',{staticClass:"item__title"},[_vm._v(_vm._s(info.key))]),_c('p',{staticClass:"item__val"},[_vm._v(_vm._s(info.val))])])}),0)]):_vm._e(),(_vm.projectNext || _vm.projectPrev)?[_c('div',{staticClass:"news-detail-more-container"},[_c('div',{staticClass:"more__inner"},[_c('div',{staticClass:"more__item scrollObj",on:{"click":function($event){return _vm.onGo(_vm.projectPrev)}}},[(_vm.projectPrev)?[_c('p',[_vm._v("previous project")]),_c('img',{staticClass:"img-cover",attrs:{"src":_vm.mobileCover(_vm.projectPrev.cover),"alt":""}})]:_vm._e()],2),_c('div',{staticClass:"more__item scrollObj",on:{"click":function($event){return _vm.onGo(_vm.projectNext)}}},[(_vm.projectNext)?[_c('p',[_vm._v("next project")]),_c('img',{staticClass:"img-cover",attrs:{"src":_vm.mobileCover(_vm.projectNext.cover),"alt":""}})]:_vm._e()],2)])])]:_vm._e(),_c('page-footer'),(_vm.$bowserMobile)?_c('mobile-back-to-top'):_vm._e()],2):_vm._e()]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dot-container"},[_c('i'),_c('i'),_c('i'),_c('i')])}]

export { render, staticRenderFns }