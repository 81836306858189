<template>
  <div class="v4-project-detail-container">
    <template v-if="!$bowserMobile">
      <div class="project-info-container" v-if="project">
        <div class="project-name">
          <p>{{ project.name.en }}</p>
          <p>{{ project.name.cn }}</p>
        </div>
        <div class="other-info">
          <div
            class="pc__direct-bts"
            @click="onClickDesc"
            :class="{
              active: isShowDesc
            }"
            v-if="project.textarea.length > 0"
          >
            <template v-if="isShowDesc">
              <p>Gallery</p>
              <p>案例图片</p>
            </template>
            <template v-else>
              <p>Description</p>
              <p>案例详情</p>
            </template>
          </div>
          <router-link
            v-if="projectPrev"
            tag="div"
            :to="{
              path: `/project/${projectPrev.id}?parentid=${project.parentid}`
            }"
            class="pc__direct-bts prev ajustWidth"
            @mouseenter.native="onLineHoverEnter"
            @mouseleave.native="onLineHoverLeave"
          >
            <p>Previous</p>
            <p>上一个</p>
            <div class="line"></div>
          </router-link>
          <router-link
            v-if="projectNext"
            tag="div"
            :to="{
              path: `/project/${projectNext.id}?parentid=${project.parentid}`
            }"
            class="pc__direct-bts next ajustWidth"
            @mouseenter.native="onLineHoverEnter"
            @mouseleave.native="onLineHoverLeave"
          >
            <p>Next</p>
            <p>下一个</p>
            <div class="line"></div>
          </router-link>
        </div>
      </div>
      <div class="fixed-back-container" @click="onBackList" v-if="project">
        <div class="dot-container">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
        <div class="text-container">back</div>
      </div>
      <div class="v4-scroll-list-container" v-if="!isShowDesc && pics">
        <div
          class="scroll-hint-container"
          :class="{
            loading: !loaded
          }"
        >
          <p>{{ loaded ? "scroll" : "loading" }}</p>
          <div class="line"></div>
        </div>
        <div class="slide-element-list" id="slide-element-list">
          <div class="slide-element">
            <div class="slide-element--inner">
              <div class="case-elements">
                <div class="case-element" v-for="(item, index) in pics" :key="index">
                  <div class="case-element--inner">
                    <div
                      class="image--outer"
                      :style="{
                        opacity: loaded ? 1 : 0
                      }"
                    >
                      <div class="image">
                        <img class="lazyload" src="" :data-src="item" alt="" />
                      </div>
                      <div class="imageBg"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="v4-fixed-detail"
        :class="{
          active: isShowDesc
        }"
        v-if="project && project.textarea"
      >
        <div class="content">
          <div
            class="content__item"
            v-for="(p, index) in project.textarea"
            :key="index"
            v-html="p"
          ></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="project-detail-wrapper" v-if="project">
        <div class="mobile-detail-title">
          <p class="en scrollObj">{{ project.name.en }}</p>
          <p class="cn scrollObj">{{ project.name.cn }}</p>
        </div>
        <div class="mobile-detail-content-container">
          <div
            class="content__item scrollObj"
            v-for="(item, index) in project.content"
            :key="index"
            :class="item.type"
            v-html="renderHtml(item)"
          ></div>
        </div>
        <div class="mobile-detail-items-container" v-if="project.items.length > 0">
          <div class="inner scrollObj">
            <div class="item__box scrollObj" v-for="info in project.items" :key="info.id">
              <p class="item__title">{{ info.key }}</p>
              <p class="item__val">{{ info.val }}</p>
            </div>
          </div>
        </div>
        <!-- 前后 -->
        <template v-if="projectNext || projectPrev">
          <div class="news-detail-more-container">
            <div class="more__inner">
              <div class="more__item scrollObj" @click="onGo(projectPrev)">
                <template v-if="projectPrev">
                  <p>previous project</p>
                  <img :src="mobileCover(projectPrev.cover)" alt="" class="img-cover" />
                </template>
              </div>
              <div class="more__item scrollObj" @click="onGo(projectNext)">
                <template v-if="projectNext">
                  <p>next project</p>
                  <img :src="mobileCover(projectNext.cover)" alt="" class="img-cover" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <page-footer></page-footer>
        <mobile-back-to-top v-if="$bowserMobile"></mobile-back-to-top>
      </div>
    </template>
  </div>
</template>

<script>
import detail from '@/plugins/v4-detail';
import { demo2OnScroll } from '@/assets/utils/common';
import { mapState } from 'vuex';
import pageFooter from '../common/page-footer.vue';
import mobileBackToTop from '../common/_mobile-back-top.vue';

export default {
  name: 'page-proejct-detail',
  components: {
    pageFooter,
    mobileBackToTop,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pics: null,
      isShowDesc: false,
      project: null,
      projectNext: null,
      projectPrev: null,
      loaded: false,
      scrollList: null,
    };
  },
  computed: {
    ...mapState(['$bowserMobile']),
  },
  watch: {
    isShowDesc(val) {
      if (val === false) {
        this.initScrollList();
      }
    },
    $route: {
      handler() {
        this.project = null;
        this.pics = null;
        this.projectNext = null;
        this.projectPrev = null;
        this.isShowDesc = false;
        this.getDetail();
      },
      immediate: true,
    },
  },
  mounted() {
    // document.querySelector('.project-bg').style.cssText = 'background-color:#fff';
    window.addEventListener('resize', this.detailOnResize);
  },
  destroyed() {
    if (!this.$bowserMobile) {
      document.documentElement.classList.remove('no-touch');
    }
    window.removeEventListener('resize', this.detailOnResize);
  },
  methods: {
    detailOnResize() {
      const that = this;
      if (!that.$bowserMobile) {
        if (that.scrollList) {
          that.scrollList.unbind();
        }
        that.loaded = false;
        that.$nextTick(() => {
          that.ajustWidth();
        });
        document.documentElement.classList.add('no-touch');
      } else {
        document.documentElement.classList.remove('no-touch');
      }
      that.initScrollList();
    },
    onLineHoverEnter(e) {
      const obj = e.currentTarget;
      obj.querySelector('.line').style.cssText = `width:${obj.dataset.width}px`;
    },
    onLineHoverLeave(e) {
      const obj = e.currentTarget;
      obj.querySelector('.line').style.cssText = `width:${obj.dataset.ow}px`;
    },
    ajustWidth() {
      const ajustItems = document.querySelectorAll('.ajustWidth');
      if (ajustItems) {
        [...ajustItems].forEach((item) => {
          const tmp = item;
          const ps = [...tmp.querySelectorAll('p')];
          tmp.dataset.width = ps['1'].offsetWidth;
          tmp.dataset.ow = item.offsetWidth;
        });
      }
    },
    onClickDesc() {
      this.isShowDesc = !this.isShowDesc;
    },
    getDetail() {
      const that = this;
      that.$fly
        .get('/api/product', {
          id: that.id,
          parentid: that.$route.query.parentid,
        })
        .then((res) => {
          const { data } = res;
          that.project = data.detail;
          that.pics = that.project.slides;
          that.projectNext = data.next;
          that.projectPrev = data.prev;
          if (!that.$bowserMobile) {
            document.documentElement.classList.add('no-touch');
          }
          that.initScrollList();
          that.$nextTick(() => {
            that.ajustWidth();
            document.title = that.project.name.cn;
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute('content', that.project.name.cn);
            document
              .querySelector('meta[name="description"]')
              .setAttribute('content', that.project.name.cn);
          });
        });
    },
    initScrollList() {
      const that = this;
      if (!that.$bowserMobile) {
        this.$nextTick(() => {
          that.scrollList = new detail(document.getElementById('slide-element-list'));
          that.scrollList.init(() => {
            that.loaded = true;
          });
        });
      } else {
        that.$nextTick(() => {
          demo2OnScroll();
        });
      }
    },
    onBackList() {
      // const url = `/projects/${this.$route}/${this.project.parentid}/${this.project.typeid}`
      // this.$router.push({
      //   path: url,
      // });
      this.$router.go(-1);
    },
    renderHtml(content) {
      return content.type === 'textarea' ? content.value : `<img src="${content.value}" />`;
    },
    onGo(data) {
      if (data) {
        this.$router.push({
          path: `/project/${data.id}?parentid=${this.project.parentid}`,
        });
      }
    },
    mobileCover(covers) {
      return covers instanceof Array ? covers['0'] : covers;
    },
  },
};
</script>

<style>
body {
  background-color: #fff;
}
</style>
