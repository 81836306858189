import { throttle } from '@/assets/utils/tools';
import LocomotiveScroll from 'locomotive-scroll'
import {
  TweenLite,
} from 'gsap';

class Detail {
  constructor(wrapper) {
    this.currentX = 0;
    this.wrapper = document.querySelector('.case-elements');
    this.wrapperBcr = this.wrapper.getBoundingClientRect();
    this.children = Array.from(this.wrapper.querySelectorAll('.case-element'));
    this.scrolling = false;
    this.scrollLeftEnd = false;
    this.scrollRightEnd = false;
    this.step = 200;
    this.duration = 0.3;
    this.scaleDuration = 0.2;
    this.originScale = 0.8;
    this.SliderElement = function () {
      this.el = wrapper;
      this.lazyloadObj = null;
    };
    this.SliderElement.prototype.setSize = function (t, width, height) {
      const obj = t;
      const orate = width / height;
      const { innerHeight } = window;
      obj.dataset.width = innerHeight * orate;
      obj.dataset.height = innerHeight;
      obj.parentNode.parentNode.parentNode.parentNode.style.cssText = `width: ${obj.dataset.width}px;height:${obj.dataset.height}px`;
    };
    this.SliderElement.prototype.lazyLoadImages = function () {
      const se = this;
      se.lazyloadObj = Array.from(se.el.querySelectorAll('.lazyload'));
      const lazyloadObj = se.el.querySelectorAll('.lazyload');
      const arr = [];
      for (let t = 0; t < lazyloadObj.length; t += 1) {
        const i = lazyloadObj[t];
        const { src } = i.dataset;
        const image = new Image();
        image.src = src;
        const p = new Promise((resolve, reject) => {
          image.onload = function () {
            i.setAttribute('src', src);
            se.onElementImgLoaded(i, image);
            se.setSize(i, image.width, image.height);
            resolve();
          };
          image.onerror = function () {
            reject();
          };
        });
        arr.push(p);
      }
      return Promise.all(arr);
    };
    this.SliderElement.prototype.onElementImgLoaded = function (t) {
      t.parentNode.parentNode.querySelector('.imageBg').classList.add('invisible');
    };
  }
}

Detail.prototype.bind = function () {
  const that = this
  const hint = document.querySelector('.scroll-hint-container');
  that.scroller = new LocomotiveScroll({
    el: that.wrapper,
    smooth: true,
    direction: 'horizontal',
    tablet: {
      breakpoint: 750
    },
    smartphone: {
      breakpoint: 750
    }
  });
  const scrollXLimit = that.wrapper.offsetWidth - window.innerWidth
  that.scroller.scroll.instance.limit.x = scrollXLimit
  that.scroller.on('scroll', (args) => {
    if (args.scroll.x > 1) {
      hint.classList.add('hide');
    } else {
      hint.classList.remove('hide');
    }
    if (args.scroll.x >= 1 && args.scroll.x <= scrollXLimit) {
      that.eleAnimateIn()
    }
  })
};

Detail.prototype.eleAnimateIn = function () {
  const that = this
  const screenWidth = window.innerWidth;
  // 每个小块
  const invisible = [];
  const visible = [];
  that.children.forEach((item) => {
    const bcr = item.getBoundingClientRect();
    const offsetLeft = bcr.left - that.step;
    const re = (offsetLeft > 0 && offsetLeft < screenWidth)
      || (offsetLeft < 0 && (offsetLeft + bcr.width) > 0);
    if (!re) {
      invisible.push(item);
    } else {
      visible.push({
        item,
        offsetLeft,
        width: bcr.width,
      });
    }
  });
  visible.forEach((item) => {
    const obj = item.item;
    obj.classList.add('visible');
    const rate = 1;
    TweenLite.to(obj.querySelector('.case-element--inner'), that.scaleDuration, {
      scale: rate,
    });
  });
  invisible.forEach((item) => {
    item.classList.remove('visible');
    TweenLite.to(item.querySelector('.case-element--inner'), that.scaleDuration, {
      scale: that.originScale,
    });
  });
}

Detail.prototype.unbind = function () {
  if (this.scroller) {
    this.scroller.destroy();
  }
};

Detail.prototype.mouseWheel = function (delta) {
  const that = this;
  const screenWidth = window.innerWidth;
  if (!that.scrolling) {
    // 整体轴
    that.scrolling = true;
    if (delta.deltaY > 0 && !that.scrollRightEnd) {
      document.querySelector('.scroll-hint-container').classList.add('hide');
      if (Math.abs(that.wrapperBcr.left) + screenWidth + that.step < that.wrapperBcr.width) {
        that.currentX -= delta.deltaY < that.step ? that.step : delta.deltaY;
        that.scrollLeftEnd = false;
      } else {
        that.currentX = screenWidth - that.wrapperBcr.width;
        that.scrollEnd = true;
      }
    } else if (delta.deltaY < 0 && !that.scrollLeftEnd) {
      if (that.wrapperBcr.left + that.step < 0) {
        that.currentX += Math.abs(delta.deltaY) < that.step ? that.step : Math.abs(delta.deltaY);
        that.scrollRightEnd = false;
      } else {
        that.currentX = 0;
        that.scrollLeftEnd = true;
      }
    }
    if (that.scrollLeftEnd) {
      document.querySelector('.scroll-hint-container').classList.remove('hide');
    }

    // 每个小块
    const invisible = [];
    const visible = [];
    that.children.forEach((item) => {
      const bcr = item.getBoundingClientRect();
      const offsetLeft = bcr.left - that.step;
      const re = (offsetLeft > 0 && offsetLeft < screenWidth)
        || (offsetLeft < 0 && (offsetLeft + bcr.width) > 0);
      if (!re) {
        invisible.push(item);
      } else {
        visible.push({
          item,
          offsetLeft,
          width: bcr.width,
        });
      }
    });

    visible.forEach((item) => {
      const obj = item.item;
      obj.classList.add('visible');
      const rate = 1;
      // if (item.offsetLeft > 0) {
      //   rate = (screenWidth - item.offsetLeft) / (screenWidth / 2);
      //   rate = rate > 0.8 ? 1 : rate;
      // } else if (item.offsetLeft < 0) {
      //   rate = (item.offsetLeft + item.width) / item.width;
      // }
      TweenLite.to(obj.querySelector('.case-element--inner'), that.scaleDuration, {
        scale: rate,
      });
    });
    invisible.forEach((item) => {
      item.classList.remove('visible');
      TweenLite.set(item.querySelector('.case-element--inner'), {
        scale: that.originScale,
      });
    });

    that.render();
  }
};

Detail.prototype.render = function () {
  const that = this;
  TweenLite.to(that.wrapper, that.duration, {
    force3D: !0,
    x: that.currentX,
    onComplete() {
      that.wrapperBcr = that.wrapper.getBoundingClientRect();
      that.scrolling = false;
    },
  });
};

Detail.prototype.init = function (callback) {
  const that = this;
  const se = new that.SliderElement();
  se.lazyLoadImages().then(() => {
    TweenLite.set(se.lazyloadObj['0'].parentNode.parentNode.parentNode, {
      scale: 1,
    });
    const offsetWidth = Number(se.lazyloadObj['0'].dataset.width) > Number(se.lazyloadObj['0'].dataset.height) ? (se.lazyloadObj['0'].dataset.width / 2) : se.lazyloadObj['0'].dataset.width;
    const marginLeft = Math.ceil(window.innerWidth - offsetWidth);
    TweenLite.fromTo(se.lazyloadObj['0'].parentNode.parentNode, 0.3,
      {
        x: 80,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
      });
    const hint = document.querySelector('.scroll-hint-container');
    const lineWidth = hint.querySelector('.line').getBoundingClientRect().width;
    TweenLite.set(hint, {
      marginLeft: marginLeft - hint.getBoundingClientRect().width + lineWidth / 2,
    });
    that.wrapper.style.cssText = `padding-left:${marginLeft}px`;
    if (callback && typeof callback === 'function') {
      callback();
    }
    that.bind();
  });
};

export default Detail;
